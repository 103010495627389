import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import style from '@/components/Contagents/ContactTabs/ContractReportTable.module.scss';

import { Box, IconButton } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { Slide } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Typography from '@mui/material/Typography';

import { useRootStore } from '@/stores';
import { IDataBalancePeriod } from '@/stores/ContragentReportBalancePeriodStore';
import { color } from '@/constants/colors';
import ContractReportTableErrorBalance
  from '@/components/Contagents/ContactTabs/ContractReportTableError/ContractReportTableErrorBalance';
import { formattedDate, valueWithSpaces, numberWithSpaces, RemovingSpaces, yearsKontur } from '@/utils/reportTable';
import ContractReportTableInputBalance
  from '@/components/Contagents/ContactTabs/ContractReportTableInput/ContractReportTableInputBalance';
import { ButtonStyled } from '@/components/atoms/Button';

export type StaticRow = {
  name: string;
  code: number;
};

export type TableCell = {
  year: number;
  form_endvalue: number | string;
};

export type TableCellPeriod = {
  year: number | string;
  value: number | string;
};

export const StaticRows: StaticRow[] = [
  { name: 'Актив', code: NaN },
  { name: 'I. Внеоборотные активы', code: NaN },
  { name: 'Нематериальные активы', code: 1110 },
  { name: 'Результаты исследований и разработок', code: 1120 },
  { name: 'Нематериальные поисковые активы', code: 1130 },
  { name: 'Материальные поисковые активы', code: 1140 },
  { name: 'Основные средства', code: 1150 },
  { name: 'Доходные вложения в материальные ценности', code: 1160 },
  { name: 'Финансовые вложения', code: 1170 },
  { name: 'Отложенные налоговые активы', code: 1180 },
  { name: 'Прочие внеоборотные активы', code: 1190 },
  { name: 'Итого по разделу I', code: 1100 },
  { name: 'II. Оборотные активы', code: NaN },
  { name: 'Запасы', code: 1210 },
  { name: 'Налог на добавленную стоимость по приобретенным ценностям', code: 1220 },
  { name: 'Дебиторская задолженность', code: 1230 },
  { name: 'Финансовые вложения (за исключением денежных эквивалентов)', code: 1240 },
  { name: 'Денежные средства и денежные эквиваленты', code: 1250 },
  { name: 'Прочие оборотные активы', code: 1260 },
  { name: 'Итого по разделу II', code: 1200 },
  { name: 'Баланс', code: 1600 },
  { name: 'Пассив', code: NaN },
  { name: 'III. Капитал и резервы', code: NaN },
  { name: 'Уставный капитал (складочный капитал, уставный фонд, вклады товарищей)', code: 1310 },
  { name: 'Собственные акции, выкупленные у акционеров', code: 1320 },
  { name: 'Переоценка внеоборотных активов', code: 1340 },
  { name: 'Добавочный капитал (без переоценки)', code: 1350 },
  { name: 'Резервный капитал', code: 1360 },
  { name: 'Нераспределенная прибыль (непокрытый убыток)', code: 1370 },
  { name: 'Итого по разделу III', code: 1300 },
  { name: 'IV. Долгосрочные обязательства', code: NaN },
  { name: 'Заёмные средства', code: 1410 },
  { name: 'Отложенные налоговые обязательства', code: 1420 },
  { name: 'Оценочные обязательства', code: 1430 },
  { name: 'Прочие обязательства', code: 1450 },
  { name: 'Итого по разделу IV', code: 1400 },
  { name: 'V. Краткосрочные обязательства', code: NaN },
  { name: 'Заёмные средства', code: 1510 },
  { name: 'Кредиторская задолженность', code: 1520 },
  { name: 'Доходы будущих периодов', code: 1530 },
  { name: 'Оценочные обязательства', code: 1540 },
  { name: 'Прочие обязательства', code: 1550 },
  { name: 'Итого по разделу V', code: 1500 },
  { name: 'Баланс', code: 1700 },
];

const StaticRowsError: StaticRow[] = [
  { name: '1600 = 1700', code: 1600_1700 },
  { name: '1100 + 1200 = 1600', code: 1100_1200_1600 },
  { name: '1300 + 1400 + 1500 = 1700', code: 1300_1400_1500_1700 },
  {
    name: '1110 + 1120 + 1130 + 1140 + 1150 + 1160 + 1170 + 1180 + 1190 = 1100',
    code: 1110_1120_1130_1140_1150_1160_1170_1180_1100,
  },
  { name: '1210 + 1220 + 1230 + 1240 + 1250 + 1260 = 1200', code: 1210_1220_1230_1240_1250_1260_1200 },
  { name: '1310 + 1320 + 1340 + 1350 + 1360 + 1370 = 1300', code: 1310_1320_1340_1350_1360_1370_1300 },
  { name: '1410 + 1420 + 1430 + 1450 = 1400', code: 1410_1420_1430_1450_1400 },
  { name: '1510 + 1520 + 1530 + 1540 + 1550 = 1500', code: 1510_1520_1530_1540_1550_1500 },
];

const specialCodes = [NaN, 1100, 1200, 1600, 1300, 1400, 1500, 1700];

const slideTransition = (props: any) => {
  return <Slide {...props} direction="left" />;
};

interface ContractReportTableBalanceProps {
  years: string[];
}

const ContractReportTableBalance: React.FC<ContractReportTableBalanceProps> = observer(({ years }) => {

  const { contragentReportBalancePeriodStore, contragentsStore } = useRootStore();
  const [activeYear, setActiveYear] = useState<string[] | undefined>([]);
  const resultsePeriod = contragentReportBalancePeriodStore.results;
  const [resultsBalancePeriod, setResultsBalancePeriod] = useState<IDataBalancePeriod[]>(resultsePeriod);
  const [isRowModified, setIsRowModified] = useState<{ [key: string]: boolean }>({});
  const [openError, setOpenError] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [approval, setApproval] = useState<boolean>(false);

  useEffect(() => {
    setResultsBalancePeriod(resultsePeriod);
  }, [resultsePeriod]);

  const { inn } = contragentsStore.selectedContragent || {};
  const innList = inn;

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const getTableDataPeriod = (): Record<string, TableCellPeriod> => {
    const tableData: Record<string, TableCellPeriod> = {};

    StaticRows.forEach((staticRow) => {
      const { name, code } = staticRow;
      years.forEach((year) => {
        const matchingResultPeriod =
          resultsBalancePeriod && resultsBalancePeriod.find((item) => item.parameter_date === year && item.code === code);
        const keyPeriod = `${code}-${year}`;
        if (matchingResultPeriod) {
          const formattedValue = numberWithSpaces(matchingResultPeriod.value);
          tableData[keyPeriod] = {
            year,
            value: formattedValue,
          };
        } else {
          tableData[keyPeriod] = {
            year,
            value: ' ',
          };
        }
      });
    });

    return tableData;
  };

  const [tableDataPeriod, setTableDataPeriod] = useState(getTableDataPeriod());
  const [tableDataPeriodOld, setTableDataPeriodOld] = useState(getTableDataPeriod());
  const [isModified, setIsModified] = useState<boolean>(false);

  useEffect(() => {
    const updatedTableDataPeriod = getTableDataPeriod();
    setTableDataPeriod(updatedTableDataPeriod);
    setTableDataPeriodOld(updatedTableDataPeriod);
  }, [resultsePeriod, resultsBalancePeriod]);

  const handleRollback = () => {
    const newTableDataPeriod = getTableDataPeriod();
    setTableDataPeriod(newTableDataPeriod);
    setIsModified(false);
    setOpenModal(false);
    setApproval(false);
  };

  useEffect(() => {
    const newTableData = getTableDataPeriod();
    setTableDataPeriod(newTableData);
  }, [resultsBalancePeriod]);

  const handleSaveForm = async () => {
    const dataToSave: {
      code: number;
      value: number | null;
      report_date: string;
      parameter_date: string;
      inn: string;
    }[] = [];
    const modifiedYears: string[] = [];

    StaticRows.forEach((staticRow) => {
      const { code } = staticRow;
      years.forEach((year) => {
        const keyPeriod = `${code}-${year}`;
        const valueType = RemovingSpaces(tableDataPeriod[keyPeriod].value.toString());
        const value = !isNaN(parseInt(valueType, 10)) ? parseInt(valueType, 10) : null;
        const inn = innList as string;

        if (isRowModified[keyPeriod]) {
          dataToSave.push({
            code,
            value,
            report_date: year,
            parameter_date: year,
            inn,
          });

          if (!modifiedYears.includes(year)) {
            modifiedYears.push(year);
          }
        }
      });
    });

    setActiveYear([...modifiedYears]);

    if (approval) {
      contragentReportBalancePeriodStore.createReports(dataToSave);
      setIsModified(false);
      setOpenModal(false);
      setOpenError(false);
    }
  };

  const handleApproval = async () => {
    const modifiedYears: string[] = [];

    StaticRows.forEach((staticRow) => {
      const { code } = staticRow;
      years.forEach((year) => {
        const keyPeriod = `${code}-${year}`;
        if (isRowModified[keyPeriod]) {
          if (!modifiedYears.includes(year)) {
            modifiedYears.push(year);
          }
        }
      });
    });

    setActiveYear([...modifiedYears]);
    setOpenModal(true);
    setApproval(true);
    setOpenError(true);
  };

  const handleСancellation = () => {
    setOpenModal(false);
  };

  const keysArray = React.useMemo(() => Object.keys(tableDataPeriod), [tableDataPeriod]);

  useEffect(() => {
    const modified = keysArray.some(key => {
      if (tableDataPeriod[key].value === '' || tableDataPeriod[key].value === 0) {
        tableDataPeriod[key].value = '';
      }

      if (tableDataPeriodOld[key].value === '' || tableDataPeriodOld[key].value === 0) {
        tableDataPeriodOld[key].value = '';
      }

      return String(tableDataPeriodOld[key].value).replace(/\s+/g, '') !== String(tableDataPeriod[key].value).replace(/\s+/g, '');
    });

    setIsModified(modified);
  }, [tableDataPeriod, tableDataPeriodOld, keysArray]);

  return (
    <>
      <Snackbar
        open={openError}
        autoHideDuration={224000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ zIndex: 1000, mb: '50px' }}
        TransitionComponent={slideTransition}
        onClose={() => setOpenError(false)}
      >
        <Box sx={{ backgroundColor: `${color.white}` }}>
          <Typography sx={{ color: `${color.black}`, fontSize: '18px', fontWeight: '600' }} variant="h5" component="h5">
            Введены некорректные данные
          </Typography>
          <ContractReportTableErrorBalance
            setIsRowModified={setIsRowModified}
            activeYear={activeYear}
            formattedDate={formattedDate}
            StaticRowsError={StaticRowsError}
            years={years}
            inputRefs={inputRefs}
            valueWithSpaces={valueWithSpaces}
            tableDataPeriod={tableDataPeriod}
          />
        </Box>
      </Snackbar>

      <Box className={style.button}>
        {isModified && (
          <>
            <IconButton onClick={handleApproval}>
              <CheckOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleRollback}>
              <HighlightOffOutlinedIcon />
            </IconButton>
          </>
        )}
        {openModal && (
          <Box className={style.modal}>
            <ButtonStyled text="Подтвердить" onClick={handleSaveForm} />
            <ButtonStyled text="Отменить" onClick={handleСancellation} />
          </Box>
        )}
      </Box>

      <div className={`${style.wrapper} ${style.wrapper__ofrTable}`}>
        <table className={style.balanceTable}>
          <thead className={style.balanceTable__header}>
          <tr>
            <th className={style.balanceTable__header__name}>Наименование</th>
            <th className={style.balanceTable__header__code}>Код строки</th>
            {years.slice(0, 10).map((year, index) => (
              <React.Fragment key={index}>
                <th className={style.balanceTable__header__year}>{formattedDate(year)}</th>
              </React.Fragment>
            ))}
          </tr>
          </thead>
          <tbody>
          {StaticRows.map((staticRow, index) => {
            const { name, code } = staticRow;

            return (
              <tr
                className={`${style.balanceTable__container} ${
                  specialCodes.includes(code) ? style.balanceTable__specialRow : ''
                }`}
                key={index}
              >
                <td className={style.balanceTable__name}>{name}</td>
                <td className={`${style.balanceTable__code} ${!isNaN(code) ? '' : style.balanceTable__nanRow}`}>
                  {code}
                </td>
                <ContractReportTableInputBalance
                  name={name}
                  setIsRowModified={setIsRowModified}
                  code={code}
                  inputRefs={inputRefs}
                  setTableDataPeriod={setTableDataPeriod}
                  tableDataPeriod={tableDataPeriod}
                  index={index}
                  years={years}
                />
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    </>
  );
});

export default ContractReportTableBalance;
