import React, { FC } from 'react';
import style from '@/components/Contagents/ContactTabs/ContractReportTable.module.scss';

import { StaticRows, TableCellPeriod } from '@/components/Contagents/ContactTabs/ContractReportTableFinancialResults';
import { getFocusNextAvailableInput, valueWithSpaces } from '@/utils/reportTable';

interface ContractReportTableInputResultsProps {
  inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
  tableDataPeriod: Record<string, TableCellPeriod>;
  setTableDataPeriod: (value: React.SetStateAction<Record<string, TableCellPeriod>>) => void;
  code: number;
  setIsRowModified: (value: React.SetStateAction<{ [key: string]: boolean }>) => void;
  setIsDataEdited: (value: React.SetStateAction<boolean>) => void;
  index: number;
  disabled?: boolean;
  years: string[];
}

const ContractReportTableInputResults: FC<ContractReportTableInputResultsProps> = (props) => {
  const {
    inputRefs,
    tableDataPeriod,
    setTableDataPeriod,
    setIsDataEdited,
    setIsRowModified,
    code,
    index,
    disabled,
    years,
  } = props;

  const handleInputChangePeriod = (event: React.ChangeEvent<HTMLInputElement>, keyPeriod: string) => {
    const newValue = event.target.value;
    setTableDataPeriod((prevData) => ({
      ...prevData,
      [keyPeriod]: { ...prevData[keyPeriod], value: newValue },
    }));

    if (setIsDataEdited) {
      setIsDataEdited(true);
      if (newValue.length > 0) {
        setIsDataEdited(true);
      } else {
        setIsDataEdited(false);
      }
    }

    setIsRowModified((prevModified) => ({
      ...prevModified,
      [keyPeriod]: true,
    }));
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, rowIndex: number, colIndex: number) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const numberOfColumns = years.slice(0, 10).length;
      const numberOfRows = StaticRows.length;

      let nextInput = getFocusNextAvailableInput(
        rowIndex + 1,
        colIndex,
        numberOfRows,
        numberOfColumns,
        inputRefs.current,
      );

      if (!nextInput && colIndex < numberOfColumns - 1) {
        nextInput = getFocusNextAvailableInput(0, colIndex + 1, numberOfRows, numberOfColumns, inputRefs.current);
      }

      nextInput?.focus();
    }
  };

  return (
    <>
      {years.slice(0, 10).map((year, indexYear) => {
        const keyPeriod = `${code}-${year}`;
        const cellDataPeriod = tableDataPeriod[keyPeriod];

        return (
          <td className={style.balanceTable__year} key={indexYear}>
            <input
              ref={(el) => inputRefs.current.push(el)}
              className={style.balanceTable__year__value}
              type="text"
              value={
                cellDataPeriod !== null && cellDataPeriod !== undefined && cellDataPeriod.value !== 0
                  ? valueWithSpaces(cellDataPeriod.value.toString())
                  : ''
              }

              disabled={disabled}
              onChange={(e) => handleInputChangePeriod(e, keyPeriod)}
              onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
            />
          </td>
        );
      })}
    </>
  );
};

export default ContractReportTableInputResults;
