import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import style from '@/components/Contagents/ContactTabs/ContractReportTable.module.scss';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import { Slide } from '@mui/material';
import { Box, IconButton } from '@mui/material';

import { useRootStore } from '@/stores';
import { IDataBalancePeriod } from '@/stores/ContragentReportBalancePeriodStore';
import { color } from '@/constants/colors';
import ContractReportTableErrorFinancialResults
  from '@/components/Contagents/ContactTabs/ContractReportTableError/ContractReportTableErrorFinancialResults';
import {
  formattedDate,
  valueWithSpaces,
  numberWithSpaces,
  RemovingSpaces,
} from '@/utils/reportTable';
import ContractReportTableInputResults
  from '@/components/Contagents/ContactTabs/ContractReportTableInput/ContractReportTableInputResults';
import { ButtonStyled } from '@/components/atoms/Button';

export type StaticRow = {
  name: string;
  code: number;
};

export type TableCellPeriod = {
  year: number | string;
  value: number | string;
};

export const StaticRows: StaticRow[] = [
  { name: 'Выручка', code: 2110 },
  { name: 'Себестоимость продаж', code: 2120 },
  { name: 'Валовая прибыль (убыток)', code: 2100 },
  { name: 'Коммерческие расходы', code: 2210 },
  { name: 'Управленческие расходы', code: 2220 },
  { name: 'Прибыль (убыток) от продажи', code: 2200 },
  { name: 'Доходы от участия в других организациях', code: 2310 },
  { name: 'Проценты к получению', code: 2320 },
  { name: 'Проценты к уплате', code: 2330 },
  { name: 'Прочие доходы', code: 2340 },
  { name: 'Прочие расходы', code: 2350 },
  { name: 'Прибыль (убыток) до налогообложения', code: 2300 },
  { name: 'Налог на прибыль', code: 2410 },
  { name: 'в т.ч. текущий налог на прибыль', code: 2411 },
  { name: 'отложенный налог на прибыль', code: 2412 },
  { name: 'Прочее', code: 2460 },
  { name: 'Чистая прибыль (убыток)', code: 2400 },
];

const StaticRowsError: StaticRow[] = [
  { name: '2110 - 2120 = 2100', code: 2110_2120_2100 },
  { name: '2100 - 2210 - 2220 = 2200', code: 2100_2210_2220_2200 },
  { name: '2200 + 2310 + 2320 - 2330 + 2340 - 2350 = 2300', code: 2200_2310_2320_2330_2340_2350_2300 },
];

const specialCodes = [2110, 2200, 2300, 2400];

const slideTransition = (props: any) => {
  return <Slide {...props} direction="left" />;
};

interface ContractReportTableFinancialResultsProps {
  years: string[];
}

const ContractReportTableFinancialResults: React.FC<ContractReportTableFinancialResultsProps> = observer(({ years }) => {

  const { contragentReportBalancePeriodStore, contragentsStore } =
    useRootStore();

  const [isDataEdited, setIsDataEdited] = useState<boolean>(false);
  const [activeYear, setActiveYear] = useState<string[] | undefined>([]);
  const resultsePeriod = contragentReportBalancePeriodStore.results;
  const [resultsBalancePeriod, setResultsBalancePeriod] = useState<IDataBalancePeriod[]>(resultsePeriod);
  const [isRowModified, setIsRowModified] = useState<{ [key: string]: boolean }>({});
  const [openError, setOpenError] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [approval, setApproval] = useState<boolean>(false);
  const [savingData, setSavingData] = useState<boolean>(false);

  useEffect(() => {
    setResultsBalancePeriod(resultsePeriod);
  }, [resultsePeriod]);

  const { inn } = contragentsStore.selectedContragent || {};
  const innList = inn;

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const getTableDataPeriod = (): Record<string, TableCellPeriod> => {
    const tableData: Record<string, TableCellPeriod> = {};

    StaticRows.forEach((staticRow) => {
      const { code } = staticRow;
      years.forEach((year) => {
        const matchingResultPeriod =
          resultsBalancePeriod && resultsBalancePeriod.find((item) => item.parameter_date === year && item.code === code);
        const keyPeriod = `${code}-${year}`;
        if (matchingResultPeriod) {
          const formattedValue = numberWithSpaces(matchingResultPeriod.value);
          tableData[keyPeriod] = {
            year,
            value: formattedValue,
          };
        } else {
          tableData[keyPeriod] = {
            year,
            value: ' ',
          };
        }
      });
    });
    return tableData;
  };

  const [tableDataPeriod, setTableDataPeriod] = useState(getTableDataPeriod());
  const [tableDataPeriodOld, setTableDataPeriodOld] = useState(getTableDataPeriod());
  const [isModified, setIsModified] = useState<boolean>(false);

  useEffect(() => {
    const updatedTableDataPeriod = getTableDataPeriod();
    setTableDataPeriod(updatedTableDataPeriod);
    setTableDataPeriodOld(updatedTableDataPeriod);
  }, [resultsBalancePeriod]);

  const handleRollback = () => {
    const newTableDataPeriod = getTableDataPeriod();
    setTableDataPeriod(newTableDataPeriod);
    setIsDataEdited(false);
  };

  useEffect(() => {
    const newTableData = getTableDataPeriod();
    setTableDataPeriod(newTableData);
  }, [resultsBalancePeriod]);

  const handleSaveForm = async () => {
    const dataToSave: {
      code: number;
      value: number | null;
      report_date: string;
      parameter_date: string;
      inn: string;
    }[] = [];
    const modifiedYears: string[] = [];

    StaticRows.forEach((staticRow) => {
      const { code } = staticRow;
      years.forEach((year) => {
        const keyPeriod = `${code}-${year}`;
        const valueType = RemovingSpaces(tableDataPeriod[keyPeriod].value.toString());
        const value = !isNaN(parseInt(valueType, 10)) ? parseInt(valueType, 10) : null;
        const inn = innList as string;
        if (isRowModified[keyPeriod]) {
          dataToSave.push({
            code,
            value,
            report_date: year,
            parameter_date: year,
            inn,
          });

          if (!modifiedYears.includes(year)) {
            modifiedYears.push(year);
          }
        }
      });
    });

    setActiveYear([...modifiedYears]);

    if (approval) {
      await contragentReportBalancePeriodStore.createReports(dataToSave);
      setIsDataEdited(false);
      setSavingData(!savingData);
      setOpenModal(false);
      setOpenError(false);

    }
  };

  const handleApproval = async () => {
    const modifiedYears: string[] = [];

    StaticRows.forEach((staticRow) => {
      const { code } = staticRow;
      years.forEach((year) => {
        const keyPeriod = `${code}-${year}`;
        if (isRowModified[keyPeriod]) {
          if (!modifiedYears.includes(year)) {
            modifiedYears.push(year);
          }
        }
      });
    });

    setActiveYear([...modifiedYears]);
    setOpenModal(true);
    setApproval(true);
    setOpenError(true);
  };

  const handleСancellation = () => {
    setOpenModal(false);
  };

  const keysArray = React.useMemo(() => Object.keys(tableDataPeriod), [tableDataPeriod]);

  useEffect(() => {
    const modified = keysArray.some(key => {
      if (tableDataPeriod[key].value === '' || tableDataPeriod[key].value === 0) {
        tableDataPeriod[key].value = '';
      }

      if (tableDataPeriodOld[key].value === '' || tableDataPeriodOld[key].value === 0) {
        tableDataPeriodOld[key].value = '';
      }

      return String(tableDataPeriodOld[key].value).replace(/\s+/g, '') !== String(tableDataPeriod[key].value).replace(/\s+/g, '');
    });

    setIsModified(modified);
  }, [tableDataPeriod, tableDataPeriodOld, keysArray]);

  return (
    <>
      <Snackbar
        open={openError}
        autoHideDuration={224000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ zIndex: 1000, mb: '50px' }}
        TransitionComponent={slideTransition}
        onClose={() => setOpenError(false)}
      >
        <Box sx={{ backgroundColor: `${color.white}` }}>
          <Typography sx={{ color: `${color.black}`, fontSize: '18px', fontWeight: '600' }} variant="h5" component="h5">
            Введены некорректные данные
          </Typography>
          <ContractReportTableErrorFinancialResults
            setIsRowModified={setIsRowModified}
            setIsDataEdited={setIsDataEdited}
            activeYear={activeYear}
            formattedDate={formattedDate}
            StaticRowsError={StaticRowsError}
            years={years}
            inputRefs={inputRefs}
            valueWithSpaces={valueWithSpaces}
            tableDataPeriod={tableDataPeriod}
          />
        </Box>
      </Snackbar>
      <Box className={style.button}>
        {isModified && (
          <>
            <IconButton onClick={handleApproval}>
              <CheckOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleRollback}>
              <HighlightOffOutlinedIcon />
            </IconButton>
          </>
        )}
        {openModal && (
          <Box className={style.modal}>
            <ButtonStyled text="Подтвердить" onClick={handleSaveForm} />
            <ButtonStyled text="Отменить" onClick={handleСancellation} />
          </Box>
        )}
      </Box>

      <div className={`${style.wrapper} ${style.wrapper__ofrTable}`}>
        <table className={style.balanceTable}>
          <thead className={style.balanceTable__header}>
          <tr>
            <th className={style.balanceTable__header__name}>Наименование</th>
            <th className={style.balanceTable__header__code}>Код строки</th>
            {years.slice(0, 10).map((year, index) => (
              <React.Fragment key={index}>
                <th className={style.balanceTable__header__year}>{formattedDate(year)}</th>
              </React.Fragment>
            ))}
          </tr>
          </thead>
          <tbody>
          {StaticRows.map((staticRow, index) => {
            const { name, code } = staticRow;
            return (
              <tr
                className={`${style.balanceTable__container} ${
                  specialCodes.includes(code) ? style.balanceTable__specialRow : ''
                }`}
                key={index}
              >
                <td className={style.balanceTable__name}>{name}</td>
                <td className={`${style.balanceTable__code} ${!isNaN(code) ? '' : style.balanceTable__nanRow}`}>
                  {code}
                </td>
                <ContractReportTableInputResults
                  years={years}
                  setIsRowModified={setIsRowModified}
                  code={code}
                  inputRefs={inputRefs}
                  setTableDataPeriod={setTableDataPeriod}
                  tableDataPeriod={tableDataPeriod}
                  setIsDataEdited={setIsDataEdited}
                  index={index}
                />
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    </>
  );
});

export default ContractReportTableFinancialResults;
