import React, { FC } from 'react';
import style from '@/components/Contagents/ContactTabs/ContractReportTable.module.scss';

import { StaticRows, TableCellPeriod } from '@/components/Contagents/ContactTabs/ContractReportTableBalance';
import { getFocusNextAvailableInput, valueWithSpaces } from '@/utils/reportTable';

interface ContractReportTableInputBalanceProps {
  inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
  tableDataPeriod: Record<string, TableCellPeriod>;
  setTableDataPeriod: (value: React.SetStateAction<Record<string, TableCellPeriod>>) => void;
  setIsRowModified?: (value: React.SetStateAction<{ [key: string]: boolean }>) => void;
  code: number;
  name: string;
  index: number;
  years: string[];
}

const ContractReportTableInputBalance: FC<ContractReportTableInputBalanceProps> = (props) => {
  const {
    inputRefs,
    tableDataPeriod,
    setTableDataPeriod,
    setIsRowModified,
    code,
    name,
    index,
    years,
  } = props;

  const handleInputChangePeriod = (event: React.ChangeEvent<HTMLInputElement>, keyPeriod: string) => {
    const newValue = event.target.value;

    setTableDataPeriod((prevData) => ({
      ...prevData,
      [keyPeriod]: { ...prevData[keyPeriod], value: newValue },
    }));

    if (setIsRowModified) {
      setIsRowModified((prevModified) => ({
        ...prevModified,
        [keyPeriod]: true,
      }));
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, rowIndex: number, colIndex: number) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const numberOfColumns = years.slice(0, 10).length;
      const numberOfRows = StaticRows.length;

      let nextInput = getFocusNextAvailableInput(
        rowIndex + 1,
        colIndex,
        numberOfRows,
        numberOfColumns,
        inputRefs.current,
      );

      if (!nextInput && colIndex < numberOfColumns - 1) {
        nextInput = getFocusNextAvailableInput(0, colIndex + 1, numberOfRows, numberOfColumns, inputRefs.current);
      }

      nextInput?.focus();
    }
  };

  return (
    <>
      {years.slice(0, 10).map((year, indexYear) => {
        const keyPeriod = `${code}-${year}`;
        const cellDataPeriod = tableDataPeriod[keyPeriod];

        const isInputDisabled =
          name === 'Актив' ||
          name === 'Пассив' ||
          name === 'I. Внеоборотные активы' ||
          name === 'II. Оборотные активы' ||
          name === 'III. Капитал и резервы' ||
          name === 'IV. Долгосрочные обязательства' ||
          name === 'V. Краткосрочные обязательства';

        return (
          <td className={style.balanceTable__year} key={indexYear}>
            {isInputDisabled ? (
              <input
                ref={(el) => inputRefs.current.push(el)}
                className={style.balanceTable__year__valueDisabled}
                type="text"
                disabled
              />
            ) : (
              <input
                ref={(el) => inputRefs.current.push(el)}
                className={style.balanceTable__year__value}
                type="text"
                value={
                  cellDataPeriod
                    ? cellDataPeriod.value !== 0
                      ? valueWithSpaces(cellDataPeriod.value.toString())
                      : ''
                    : ''
                }
                onChange={(e) => handleInputChangePeriod(e, keyPeriod)}
                onKeyDown={(e) => handleInputKeyDown(e, index, indexYear)}
              />
            )}
          </td>
        );
      })}
    </>
  );
};

export default ContractReportTableInputBalance;
