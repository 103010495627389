export const formattedDate = (date: string) => {
  const [yyyy, mm, dd] = date.split('-');
  return `${dd}.${mm}.${yyyy}`;
};

export const valueWithSpaces = (value: string | null): string => {
  if (value === null) {
    return '';
  }

  const numericValue = value.replace(/\s/g, '').replace(/[^\d-]/g, '');
  const sign = numericValue.startsWith('-') ? '-' : '';
  const absoluteValue = numericValue.replace(/^-/, '');
  const formattedValue = sign + absoluteValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return formattedValue;
};

export const numberWithSpaces = (number: number): number => {
  const numericValue = Math.abs(number);
  const formattedValue = parseFloat(numericValue.toString().replace(/\s/g, '').replace(/\D/g, ''));
  return formattedValue * Math.sign(number);
};

export const RemovingSpaces = (value: string | null): string => {
  if (value === null) {
    return '';
  }

  const numericValue = value.replace(/\s/g, '');
  return isNaN(Number(numericValue)) ? '' : numericValue;
};

function getLastFiveYearsAscending() {
  const currentYear = new Date().getFullYear();
  const lastFiveYears = [];
  for (let i = 0; i < 5; i++) {
    lastFiveYears.push(currentYear - 5 + i);
  }
  return lastFiveYears;
}

export const yearsKontur = getLastFiveYearsAscending();
export const yearsKonturString = getLastFiveYearsAscending().map(year => year.toString());

export const getFocusNextAvailableInput = (
  rowIndex: number,
  colIndex: number,
  numberOfRows: number,
  numberOfColumns: number,
  inputRefs: (HTMLInputElement | null)[],
) => {
  let nextRow = rowIndex;
  let nextCol = colIndex + 1;

  while (nextRow < numberOfRows) {
    const nextInput = inputRefs[nextRow * numberOfColumns + colIndex];
    if (nextInput && !nextInput.disabled) {
      return nextInput;
    }
    nextRow++;
  }

  nextRow = 0;
  while (nextCol < numberOfColumns) {
    nextRow = rowIndex;
    while (nextRow < numberOfRows) {
      const nextInput = inputRefs[nextRow * numberOfColumns + nextCol];
      if (nextInput && !nextInput.disabled) {
        return nextInput;
      }
      nextRow++;
    }
    nextCol++;
  }

  return null;
};
